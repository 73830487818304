import React, { useEffect, useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/Layout/Layout';
import { getSafe } from '../utils/getSafe';
import Appointment from '../components/Appointment/Appointment';
import Title from '../UI/Title';

const Agendar = ({ transitionStatus, ...props }) => {
    const elRefs = useRef([]);

    useEffect(() => {
        if (transitionStatus === 'entered') {
            const { search } = props.location;
            let id = -1;
            if (search !== '') {
                id = parseInt(search.slice(-1)) - 1;
                elRefs.current[id] &&
                    elRefs.current[id].scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
            }
        }
        return () => {
            window.scrollTo(0, 0);
        };
    }, [transitionStatus, props.location]);

    return (
        <StaticQuery
            query={graphql`
                query {
                    contentfulAgendarPagina {
                        descripcionSeccion {
                            childMarkdownRemark {
                                html
                            }
                        }
                        tituloSeccion
                    }
                    allContentfulNutricionistas(sort: { fields: orden, order: ASC }) {
                        edges {
                            node {
                                nombre
                                usuarioCliniweb
                                orden
                            }
                        }
                    }
                }
            `}
            render={dataAux => {
                const title = getSafe(() => dataAux.contentfulAgendarPagina.tituloSeccion, '');
                const description = getSafe(
                    () => dataAux.contentfulAgendarPagina.descripcionSeccion.childMarkdownRemark.html,
                    ''
                );
                const nutricionistas = getSafe(() => dataAux.allContentfulNutricionistas.edges, []);
                return (
                    <Layout title="Agendar" {...props}>
                        <section className="schedule gray">
                            <div className="child">
                                <div className="cfp-container inner">
                                    <Title delay={200} text={title} />
                                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                        <div
                                            className="extra-info text"
                                            style={{ marginTop: '40px' }}
                                            dangerouslySetInnerHTML={{ __html: description }}
                                        />
                                    </ScrollAnimation>
                                    {nutricionistas.map((nutri, i) => {
                                        const { nombre, usuarioCliniweb } = nutri.node;
                                        if (!usuarioCliniweb) return null;
                                        return (
                                            <div
                                                key={i}
                                                ref={el => (elRefs.current[i] = el)}
                                                className="schedule-container"
                                            >
                                                <div className={`title-container`}>
                                                    <div className={`cfp-content-title`}>{nombre}</div>
                                                </div>
                                                <div className="appointment-content text">
                                                    Si presenta algun problema para visualizar el cuadro de la reserva,
                                                    o si lo desea, puede reservar directamente su cita con la{' '}
                                                    <b>{nombre}</b> en la página web de ClinicWeb, haciendo click{' '}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={`https://widgets.cliniweb.com/citas/${usuarioCliniweb}`}
                                                    >
                                                        aquí.
                                                    </a>
                                                </div>
                                                <Appointment nutricionista={nombre} iframe={usuarioCliniweb} />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="rectangle-left-bottom">
                                    <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                                        <img src="/rectangle.svg" alt="Rectangle" className="rectangle-img" />
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </section>
                    </Layout>
                );
            }}
        />
    );
};

export default Agendar;
