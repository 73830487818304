import React, { useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import './Appointment.scss';

const Appointment = ({ nutricionista, iframe }) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className="appointment">
            <div className="iframe">
                <div className="iframe-container">
                    {loading && (
                        <LoadingSpinner backgroundColor="white" width="100%" height="100%" bulletColor="black" />
                    )}
                    <iframe
                        id="iframe"
                        src={`https://widgets.cliniweb.com/citas/${iframe}`}
                        title={`Reserva ${nutricionista}`}
                        onLoad={() => setLoading(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Appointment;
